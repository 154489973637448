<template>
  <div style="height: 100%">
    <div v-for="{ value, data } in items" :key="value" class="workspaceRow">
      <div
        v-for="item in data"
        :key="item.id"
        :style="{ backgroundColor: computedBackgroundColor(item.color) }"
        class="card"
        @click.stop="$router.push({ name: 'workspaces-browse' })"
      >
        <div class="content">
          <div
            class="row items-center q-pa-sm"
            :color="item.color"
            @click="$router.push({ name: 'workspaces-browse' })"
          >
            <!-- <slot name="avatar" :item="item">
                <BaseIcon :name="item.icon" :color="item.color" />
              </slot> -->
            <div class="col mx-md">
              <div class="title ellipsis">
                {{ item[title.name] }}
              </div>
            </div>
            <div class="col-auto mx-md">
              <BaseActionButton
                icon="eva-external-link-outline"
                class="right q-ml-sm"
                color="primary"
                size="15px"
                :no-border="true"
                :is-flat="true"
                @click="$router.push({ name: 'workspaces-browse' })"
              ></BaseActionButton>
            </div>
          </div>
          <div class="row items-center q-pa-sm">
            <div class="subtitle col">
              {{ item[subtitle.name] || "-" }}
            </div>
          </div>
          <div class="row items-center q-pa-xs q-ml-sm">
            <div v-for="column in _columns" :key="column.id" class="col-12">
              <div class="row" @click.stop="goto(column.route)">
                <BaseActionButton
                  icon="eva-arrow-forward"
                  color="secondary"
                  size="18px"
                  class="q-mr-xs menu"
                  :no-border="true"
                  :is-flat="true"
                ></BaseActionButton>
                <div class="titleTag q-mr-xs">
                  {{ column.label }}
                </div>
                <div class="titleTag">
                  ({{ item[column.name].count || "0" }})
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "WorkspaceOverviewGrid",

  components: {},

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      stats: [
        {
          id: this.$nano.id(),
          label: "repositoriesCount",
          route: "repositories-browse",
        },
        {
          id: this.$nano.id(),
          label: "workflowsCount",
          route: "workflows-browse",
        },
        {
          id: this.$nano.id(),
          label: "portalsCount",
          route: "portals-browse",
        },
        {
          id: this.$nano.id(),
          label: "tasksCount",
          route: "tasks-browse",
        },
      ],
    };
  },

  computed: {
    ...mapState(["darkTheme"]),

    title() {
      return this.columns.find((column) => column.type === "TITLE");
    },

    subtitle() {
      return this.columns.find((column) => column.type === "SUBTITLE");
    },

    _columns() {
      return this.columns.filter(
        (column) => column.type === "NORMAL" && column.isVisible
      );
    },
  },

  methods: {
    computedBackgroundColor(item) {
      const color = this.darkTheme
        ? this.getDarkColor(item)
        : this.boxColor(item);
      return color;
    },

    handleMore(workspaceId) {
      this.$emit("more", workspaceId);
    },

    goto(route) {
      this.$router.push({ name: route });
    },

    boxColor(item) {
      switch (item) {
        case "amber":
          return "#fff7df";
        case "pink":
          return "#fff1f4";
        case "deep-orange":
          return "#ffece6";
        case "green":
          return "#e5fce5";
        case "brown":
          return "#feecec";
        case "secondary":
          return "#f0fdff";
        case "primary":
          return "#f6edff";
        case "teal":
          return "#eefcfc";
        case "light-green":
          return "#f3ffe5";
        case "blue":
          return "#f5f5ff";
        case "purple":
          return "#purple";
        case "indigo":
          return "indigo";
        case "light-blue":
          return "#fff0c5";
        case "lime":
          return "lime";
        case "orange":
          return "orange";
        case "red":
          return "red";
        case "deep-purple":
          return "deep-purple";

        default:
          return "#f1f1f1";
      }
    },

    getDarkColor(item) {
      switch (item) {
        case "amber":
          return "#3c321f";
        case "pink":
          return "#3f1d26";
        case "deep-orange":
          return "#452f2b";
        case "green":
          return "#244f24";
        case "brown":
          return "#493a3a";
        case "secondary":
          return "#0d1b1e";
        case "primary":
          return "#2d2539";
        case "teal":
          return "#1e2c2c";
        case "light-green":
          return "#1e3c1e";
        case "blue":
          return "#1e1e32";
        case "purple":
          return "#2e2e4e";
        case "indigo":
          return "#1e1e32";
        case "light-blue":
          return "#302d1e";
        case "lime":
          return "#3c3c1e";
        case "orange":
          return "#4d2b00";
        case "red":
          return "#4d0000";
        case "deep-purple":
          return "#2e2e4e";

        default:
          return "#121212";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 6px;
  height: 100%;
  margin-right: 15px;
  .content {
    width: 210px;
  }

  &:hover {
    cursor: pointer;
  }

  .title {
    @extend .title-2;
    text-transform: capitalize;
    color: #7c7f91;
  }
  .titleTag {
    font-weight: 400;
    color: var(--secondary);
  }
  .subtitle {
    color: var(--icon-color-inverted);
    height: 60px;
    overflow: hidden;
  }

  .label {
    color: var(--icon-color-inverted);
  }

  .stat {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    transition: all 0.25s ease;

    .label {
      color: var(--icon-color-inverted);
      text-decoration: underline;
    }

    &:hover {
      cursor: pointer;
      color: var(--secondary);
      background-color: var(--hover-bg-color);

      .label {
        color: var(--secondary);
      }
    }
  }

  .detailsCount {
    text-align: right;
    margin-right: 10px;
    margin-top: 8px;
  }
}

.workspaceRow {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 100%;

  #action-btn.menu {
    height: 22px !important;
    width: 22px !important;
  }
}
</style>

<template>
  <div class="wrapper">
    <div class="row">
      <div v-if="!isHintExpand" class="col-0-5 q-mt-md q-mr-md">
        <BaseActionButton
          icon="mdi-rocket"
          color="secondary"
          class="started"
          size="32px"
          :no-border="true"
          @click="isHintExpand = true"
        />
      </div>
      <div v-if="isHintExpand" class="col-3 q-mt-md q-mr-md">
        <div class="workspaceHints q-mb-md">
          <div class="row q-mb-sm">
            <div class="description q-mt-sm col-11">
              Getting started - Create a new workspace!
            </div>
            <div class="col">
              <BaseActionButton
                icon="eva-expand-outline"
                size="18px"
                :is-flat="true"
                :no-border="true"
                class="q-mr-xs"
                @click="isHintExpand = false"
              />
            </div>
          </div>
          <div v-for="hint in workspaceHints" :key="hint">
            <div
              :style="{ background: getColor(hint.backcolor) }"
              class="hintTab"
            >
              <div class="row items-center">
                <div class="col-2">
                  <BaseActionButton :icon="hint.icon" :color="hint.color" />
                </div>
                <div class="col description">
                  {{ hint.title }}
                  <span class="highlightText">{{ hint.highlight }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col q-mt-md">
        <div class="workspaceList q-mb-md">
          <div class="row">
            <div class="title-2 q-mb-sm col-10">Welcome!</div>
            <div
              class="has-link q-pl-xl"
              @click="$router.push({ name: 'workspaces-browse' })"
            >
              View All
            </div>
            <div class="col-12">
              <div class="description q-mb-sm">
                Connect to one of your organization's workspaces.
              </div>
            </div>
          </div>

          <div class="cover row items-center q-mt-sm">
            <div class="col-0-5">
              <BaseActionButton
                icon="eva-arrow-ios-back-outline"
                class="left q-mr-sm"
                color="grey-5"
                size="32px"
                :no-border="true"
                :is-flat="true"
                @click="leftScroll"
              ></BaseActionButton>
            </div>
            <div class="col">
              <WorkspaceOverviewGrid
                :items="items"
                :columns="columns"
              ></WorkspaceOverviewGrid>
            </div>
            <div class="col-0-5">
              <BaseActionButton
                icon="eva-arrow-ios-forward-outline"
                class="right q-ml-sm"
                color="grey-5"
                size="32px"
                :no-border="true"
                :is-flat="true"
                @click="rightScroll"
              ></BaseActionButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { workspace } from "@/api/factory.js";
import DataType from "@/constants/data-type.js";
import WorkspaceOverviewGrid from "./workspace-overview/WorkspaceOverviewGrid.vue";
export default {
  name: "WorkspacesOverview",

  components: { WorkspaceOverviewGrid },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "repositoriesCount",
          label: "Repositories",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.NUMBER,
          route: "repositories-browse",
        },
        {
          id: this.$nano.id(),
          name: "workflowsCount",
          label: "Workflows",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.NUMBER,
          route: "workflows-browse",
        },
        {
          id: this.$nano.id(),
          name: "portalsCount",
          label: "Portals",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.NUMBER,
          route: "portals-browse",
        },
        {
          id: this.$nano.id(),
          name: "tasksCount",
          label: "Tasks",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.NUMBER,
          route: "tasks-browse",
        },
      ],
      workspaces: [],
      isLoading: false,
      isHintExpand: false,
      workspaceHints: [
        {
          icon: "eva-play-circle",
          title: "Watch a video to know how to create and manage workspaces.",
          highlight: "",
          color: "secondary",
          backcolor: "#e0f5fa",
        },
        {
          icon: "eva-plus-circle",
          title: "Know how to create and manage workspaces.",
          highlight: "Create a workspace from scratch.",
          color: "primary",
          backcolor: "#faf4fe",
        },
        {
          icon: "mdi-rocket",
          title: "Want to explore using an existing template.",
          highlight: "Create using a template.",
          color: "secondary",
          backcolor: "#e0f5fa",
        },
        {
          icon: "eva-question-mark-circle",
          title: "Need some help about workspaces.",
          highlight: "Ask a EZOFIS support personnel.",
          color: "secondary",
          backcolor: "#e0f5fa",
        },
      ],
    };
  },

  computed: {
    ...mapState(["darkTheme"]),

    items() {
      return this.workspaces.map(({ key, value }) => ({
        value: key,
        data: value.map((workspace) => ({
          id: workspace.id,
          name: workspace.name,
          description: workspace.description,
          color: workspace.color,
          repositoriesCount: {
            count: workspace.repositoriesCount,
            icon: "eva-archive",
          },
          workflowsCount: {
            count: workspace.workflowsCount,
            icon: "mdi-sitemap",
          },
          portalsCount: {
            count: workspace.portalsCount,
            icon: "eva-globe",
          },
          tasksCount: {
            count: workspace.tasksCount,
            icon: "eva-cube",
          },
          icon: "mdi-briefcase",
        })),
      }));
    },
  },

  mounted() {
    this.getWorkspaces();
  },

  created() {
    this.$emit("click");
  },

  methods: {
    async getWorkspaces() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await workspace.getWorkspaces({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "ASC" },
        groupBy: "",
        filterBy: [],
        itemsPerPage: 50,
        currentPage: 1,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;

      this.workspaces = data || [];
    },

    leftScroll() {
      const left = document.querySelector(".workspaceRow");
      left.scrollBy(-200, 0);
    },
    rightScroll() {
      const right = document.querySelector(".workspaceRow");
      right.scrollBy(200, 0);
    },

    leftExploreScroll() {
      const left = document.querySelector(".workspaceExploreRow");
      left.scrollBy(-200, 0);
    },
    rightExploreScroll() {
      const right = document.querySelector(".workspaceExploreRow");
      right.scrollBy(200, 0);
    },
    getColor(value) {
      switch (value) {
        case "#e0f5fa":
          return this.darkTheme ? "#1c313a" : value;
        case "#faf4fe":
          return this.darkTheme ? "#2e2e4e" : value;
        default:
          return value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  height: calc(100vh - 208px);
}

.started {
  height: 50px !important;
  width: 50px !important;
}

.workspaceList {
  // height: calc(calc(100vh - 250px) / 2);
  // height: 100%;
  //border: 1px solid #fff;
  padding: 20px;
  border-radius: 10px;
  //background: white;
  background-color: var(--component-bg-color);
  border: 1px solid var(--component-bg-color);
}

.description {
  color: var(--icon-color-inverted);
  margin-bottom: 4px;
}

.cover {
  // position: relative;
  height: 80%;

  // #action-btn {
  //   width: 30px !important;
  //   height: 30px !important;
  // }
}

.left {
  // position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.right {
  // position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.has-link {
  cursor: pointer;
  color: var(--secondary);
  float: right;
  font-weight: 600;
  margin-left: 6%;
}

.has-link:hover {
  color: $primary;
  font-weight: 600;
}

.workspaceHints {
  // height: calc(100vh - 235px);
  //border: 1px solid #fff;
  padding: 10px;
  border-radius: 10px;
  //background: white;
  background-color: var(--component-bg-color);
  border: 1px solid var(--component-bg-color);

  .hintTab {
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 6px;
  }

  .highlightText {
    color: $secondary;
    font-weight: bold;
  }
}
</style>
